





















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    CharacterInfoCard: () => import('./CharacterInfoCard.vue'),
    CharacterEquippedItems: () => import('./CharacterEquippedItems.vue'),
    ItemDetailsViewer: () => import('@/components/Item/ItemDetailsViewer.vue'),
    ItemCard: () => import('@/components/Item/ItemCard.vue')
  }
}) 
export default class CharacterProfile extends Vue {
  @Prop() data
}
